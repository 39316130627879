import axios from '@/utils/axios';
const baseURL = '/api'
// 养老手环

// 手环绑定
export const bindBracelet = (data) => {
  return axios({
    baseURL,
    // url: '/app/convenient/oldpeople/bindBracelet',
    url: '/app/convenient/userold/bindBracelet',
    method: 'post',
    data
  });
};
// 绑定详情
export const bindDetail = (data) => {
  return axios({
    baseURL,
    url: '/app/convenient/userold/detail',
    method: 'get',
    data
  });
};