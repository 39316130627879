 <!-- 绑定手环 -->
<template>
  <div> 
    <van-form validate-first label-width="65" @submit="submit">
      <van-field
        v-model="formData.name"
        name="name"
        label="姓名："
        placeholder="请输入姓名"
        disabled
        :rules="[{ required: true }]"
      />
      <van-field
        name="sex"
        label="性别："
        disabled
        :rules="[{ required: true, message: '请选择性别' }]"
      >
        <template #input>
          <van-radio-group v-model="formData.sex" direction="horizontal" disabled>
            <van-radio :name="1">男</van-radio>
            <van-radio :name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="formData.account"
        name="account"
        label="身份证号:"
        placeholder="请输入身份证号"
        :rules="[{ required: true }]"
        disabled
      />
      <!-- <van-field
        v-model="formData.userPhone"
        name="userPhone"
        label="手机号:"
        placeholder="请输入手机号"
        :rules="[{ required: true }]"
      /> -->
      <van-field
        v-model="formData.braceletSn"
        name="braceletSn"
        center
        clearable
        label="设备ID:"
        placeholder="请输入设备ID"
        :rules="[{ required: true }]"
      >
        <template #button>
          <i class="iconfont icon-scanning" @click="scan" />
        </template>
      </van-field>
      <van-field
        v-model="formData.braceletPhone"
        name="braceletPhone"
        label="设备手机号:"
        placeholder="请输入设备手机号"
        :rules="[{ validator: isMobile, message: '请输入正确手机号' }]"
      />
      <van-field
        v-model="formData.emergencyContact"
        name="emergencyContact"
        label="紧急联系人姓名:"
        placeholder="请输入紧急联系人姓名"
        center
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="formData.emergencyContactPhone"
        name="emergencyContactPhone"
        label="紧急联系人手机号:"
        center
        placeholder="紧急联系人手机号"
        :rules="[{ validator: isMobile, message: '请输入正确手机号' }]"
      />
      <!-- <van-field
        v-model="formData.address"
        name="address"
        label="地址:"
        placeholder="请输入地址"
        :rules="[{ required: true }]"
      /> -->
      <van-field
        v-model="formData.braceletRemark"
        name="braceletRemark"
        label="备注:"
        placeholder="请输入备注"
      />
      <div class="subBtn" >
        <van-button block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template> 

<script> 
// import wx from 'jweixin-js';
import { getWxConfigs } from '@/api/wxApi'
import { bindBracelet, bindDetail } from '@/api/bindingApi'
import { isMobile } from '@/utils/validate'
// import wx from 'weixin-js-sdk';
import wx from 'weixin-jsapi'
export default {
  name: "Binding",
  components: {},
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: 100,
    },
  },
  data() {
    return {
      formData: {
        braceletSn: "",
        braceletPhone: "",
        emergencyContact: "",
        emergencyContactPhone: "",
        braceletRemark: "",
        // address: "",
        name: "",
        sex: "",
        account: "",
        userPhone: "",
      },
      list: [],
      loading: false,
      finished: false,
      
    };
  },
  // 计算属性
  computed: {},

  // watch
  watch: {},

  // 创建完成（可以访问当前this实例）
  created() { 
    this.getInfo()
    // this.formData.name = this.$store.getters.userInfo.realName
    // this.formData.sex = this.$store.getters.userInfo.sex
    // this.formData.account = this.$store.getters.userInfo.account
    // this.formData.userPhone = this.$store.getters.userInfo.phone

    // this.formData.braceletSn = this.$store.getters.userInfo.braceletSn
    // this.formData.braceletPhone = this.$store.getters.userInfo.braceletPhone
    // this.formData.emergencyContact = this.$store.getters.userInfo.emergencyContact
    // this.formData.emergencyContactPhone = this.$store.getters.userInfo.emergencyContactPhone
    // this.formData.braceletRemark = this.$store.getters.userInfo.braceletRemark

      // let url = location.href.split('#')[0]
      // getWxConfigs({url: url}).then(res => {
      //   this.jsonData = res.data;
      //   console.log(this.jsonData.appId)
      //   console.log("" + this.jsonData.wxConfig.timestamp)
      //   console.log(this.jsonData.wxConfig.nonceStr)
      //   console.log(this.jsonData.wxConfig.signature)
      //   window.wx.config({
      //     debug: false,
      //     appId: this.jsonData.appId,
      //     timestamp: "" + this.jsonData.wxConfig.timestamp,
      //     nonceStr: this.jsonData.wxConfig.nonceStr,
      //     signature: this.jsonData.wxConfig.signature,
      //     jsApiList: ['checkJsApi', 'scanQRCode']
      //   })
      // })
    // const oScript = document.createElement("script");
    // oScript.type = "text/javascript";
    // oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
    // oScript.onload = this.scan;
    // document.body.appendChild(oScript);
  },

  // 挂载完成（可以访问DOM元素）
  mounted() {   
    document.addEventListener('WeixinOpenTagsError', function (e) {
      console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开发标签，JS-SDK其他功能不受影响
    }); 
  },

  // 方法集合
  methods: { 
    isMobile: isMobile,
    scan(){
      let _this = this
      let url = location.href.split('#')[0]
  
      getWxConfigs({url: url}).then(res => {
  
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: "" + res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表
        })
        wx.error(function (res) {
          // alert("出错了：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
        });
        wx.ready(function () {
          wx.scanQRCode({ 
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) { 
              if(res.resultStr) {
                _this.formData.braceletSn = res.resultStr
              } else {
                this.$toast('无效二维码，请手动输入')
              }
              // _this.number = res.resultStr.substring(res.resultStr.indexOf("=")+1);
              // _this.searchResults();
            }
          })
        })
      })

    },
    getInfo() {
      this.$store.commit('SET_IS_LOADING', true)
      bindDetail().then(res => {
        this.formData.name = this.$store.getters.userInfo.realName
        this.formData.sex = this.$store.getters.userInfo.sex
        this.formData.account = this.$store.getters.userInfo.account
        this.formData.userPhone = this.$store.getters.userInfo.phone

        this.formData.braceletSn = res.data.braceletSn
        this.formData.braceletPhone = res.data.braceletPhone
        this.formData.emergencyContact = res.data.emergencyContact
        this.formData.emergencyContactPhone = res.data.emergencyContactPhone
        this.formData.braceletRemark = res.data.braceletRemark
        this.$store.commit('SET_IS_LOADING', false)
      }).catch(() => {
        this.$store.commit('SET_IS_LOADING', false)
      })
    },
    submit(val) {
      let data = {
        openId: this.$store.getters.userId,
        braceletSn: this.formData.braceletSn,
        braceletPhone: this.formData.braceletPhone,
        emergencyContact: this.formData.emergencyContact,
        emergencyContactPhone: this.formData.emergencyContactPhone,
        braceletRemark: this.formData.braceletRemark,
      }
      bindBracelet(data).then(res => {
        if (res.code === 200) {
            this.$store.dispatch("GetInfo").then(()=> {
                let _this = this
                this.$toast({
                    type: 'success',
                    message: '绑定成功',
                    duration: 500,
                    onClose: function() {
                        _this.$router.go(-1) 
                    }
                })   
            }) 
        } else {
          this.$toast.fail(res.msg)
        }
          
      })
    }, 

  },
};
</script>

<style lang='scss'>
</style>
<style lang='scss' scoped>
// @import url(s); 引入公共css类
.subBtn {
  margin: 1rem 24px;
}
</style>
